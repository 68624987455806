<template>
  <div class="service-form-part">
    <ServiceTypeField :choices="choices" v-on="$listeners" />
  </div>
</template>

<script>
import ServiceTypeField from 'chimera/all/themes/mars/components/form/fields/serviceType/ServiceTypeField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import TSelectableButton from 'chimera/all/components/elements/TSelectableButton'

export default {
  name: 'ServiceTypeFormPart',

  components: {
    ServiceTypeField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Gratis introductieles',
          'Gratis introductieles',
          'Gratis introductieles',
          { component: TSelectableButton },
        ),
        new Selectable('Losse les', 'Losse les', 'Losse les', {
          component: TSelectableButton,
        }),
        new Selectable('Rijlessen', 'Rijlessen', 'Rijlessen', {
          component: TSelectableButton,
        }),
        new Selectable(
          'Pakketten (Theorie + Rijlessen)',
          'Pakketten (Theorie + Rijlessen)',
          'Pakketten (Theorie + Rijlessen)',
          { component: TSelectableButton },
        ),
      ],
    }
  },
}
</script>
