<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#004E89"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 37.5C30.9036 37.5 36.5 31.9036 36.5 25C36.5 18.0964 30.9036 12.5 24 12.5C17.0964 12.5 11.5 18.0964 11.5 25C11.5 31.9036 17.0964 37.5 24 37.5ZM38.5 25C38.5 33.0081 32.0081 39.5 24 39.5C15.9919 39.5 9.5 33.0081 9.5 25C9.5 16.9919 15.9919 10.5 24 10.5C32.0081 10.5 38.5 16.9919 38.5 25Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 27C25.1046 27 26 26.1046 26 25C26 23.8954 25.1046 23 24 23C22.8954 23 22 23.8954 22 25C22 26.1046 22.8954 27 24 27ZM28 25C28 27.2091 26.2091 29 24 29C21.7909 29 20 27.2091 20 25C20 22.7909 21.7909 21 24 21C26.2091 21 28 22.7909 28 25Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.0838 28.2841C37.9269 28.8136 37.3704 29.1157 36.8409 28.9588L26.7159 25.9588C26.1864 25.8019 25.8843 25.2454 26.0412 24.7159C26.1981 24.1864 26.7546 23.8843 27.2841 24.0412L37.4091 27.0412C37.9386 27.1981 38.2407 27.7546 38.0838 28.2841ZM9.9162 28.2841C9.7593 27.7546 10.0614 27.1981 10.5909 27.0412L20.7159 24.0412C21.2454 23.8843 21.8019 24.1864 21.9588 24.7159C22.1157 25.2454 21.8136 25.8019 21.2841 25.9588L11.1591 28.9588C10.6296 29.1157 10.0731 28.8136 9.9162 28.2841ZM24 23C23.4477 23 23 22.5523 23 22L23 11.5C23 10.9477 23.4477 10.5 24 10.5C24.5523 10.5 25 10.9477 25 11.5L25 22C25 22.5523 24.5523 23 24 23Z"
      fill="white"
    />
    <path
      d="M41 16C41 19.866 37.866 23 34 23C30.134 23 27 19.866 27 16C27 12.134 30.134 9 34 9C37.866 9 41 12.134 41 16Z"
      fill="#004E89"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34 22C37.3137 22 40 19.3137 40 16C40 12.6863 37.3137 10 34 10C30.6863 10 28 12.6863 28 16C28 19.3137 30.6863 22 34 22ZM34.4484 12.7418C34.265 12.3702 33.7351 12.3702 33.5517 12.7418L32.8288 14.2064C32.756 14.3538 32.6154 14.456 32.4527 14.4798L30.8353 14.7162C30.4253 14.7761 30.2619 15.2801 30.5587 15.5691L31.7283 16.7083C31.8463 16.8232 31.9001 16.9888 31.8723 17.151L31.5963 18.7598C31.5263 19.1683 31.955 19.4798 32.3219 19.2869L33.7673 18.5267C33.913 18.4501 34.0871 18.4501 34.2328 18.5267L35.6782 19.2869C36.045 19.4798 36.4738 19.1683 36.4038 18.7598L36.1278 17.151C36.1 16.9888 36.1538 16.8232 36.2718 16.7083L37.4414 15.5691C37.7382 15.2801 37.5748 14.7761 37.1648 14.7162L35.5474 14.4798C35.3847 14.456 35.2441 14.3538 35.1713 14.2064L34.4484 12.7418Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWheelWithStar',
}
</script>
