<template>
  <FormModal next-btn-text="Volgende">
    <template #body>
      <h1 class="form-modal__title">
        {{ $t('title') }}
      </h1>
      <p class="form-modal__description">
        {{ $t('description') }}
      </p>
      <SelectTypeFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/mars/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import SelectTypeFormStep from '~/components/form/steps/selectType/SelectTypeFormStep'

export default {
  name: 'TypeAutorijles',

  components: {
    SelectTypeFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type autorijles',
      path: '/offertes-aanvragen/type-autorijles',
      progressValue: 0,
      checkoutStep: 1,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Waar ben je naar op zoek?",
    "description": "Vertel ons in welk type autorijlessen je geïnteresseerd bent."
  }
}
</i18n>
