<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V12C11 12.2652 11.1054 12.5196 11.2929 12.7071L12.7929 14.2071C13.1834 14.5976 13.8166 14.5976 14.2071 14.2071C14.5976 13.8166 14.5976 13.1834 14.2071 12.7929L13 11.5858V9Z"
      fill="#F7C59F"
    />
    <path
      d="M14.1841 2.00001C14.4345 1.99899 14.6761 2.09191 14.8612 2.26039C15.0464 2.42888 15.1616 2.66069 15.1841 2.91001L15.3508 4.73338C16.1428 5.09921 16.8649 5.59075 17.4915 6.18239L17.176 2.73002L14.1782 1.6498e-05L17.1759 2.72901C17.1081 1.98143 16.7625 1.28642 16.2073 0.781184C15.6524 0.276223 14.9285 -0.00247015 14.1782 1.6498e-05L9.82913 1.69152e-05C9.08071 0.0007218 8.35958 0.281148 7.8073 0.786246C7.25515 1.29123 6.9116 1.98483 6.84413 2.73002L6.53052 6.16182C7.15751 5.57418 7.87922 5.08637 8.67027 4.72377L8.83601 2.91002C8.85847 2.66156 8.97298 2.43046 9.15707 2.26209C9.34108 2.0938 9.58133 2.00033 9.83069 2.00002L14.1801 2.00002L14.1841 2.00001Z"
      fill="#F7C59F"
    />
    <path
      d="M9.83789 21.8999C9.58756 21.9009 9.34595 21.808 9.1608 21.6395C8.97564 21.471 8.86042 21.2392 8.83789 20.9899L8.67127 19.1665C7.87919 18.8007 7.15708 18.3092 6.53052 17.7175L6.84601 21.1699L9.84378 23.8999L6.8461 21.1709C6.91391 21.9185 7.25951 22.6135 7.81471 23.1187C8.36962 23.6237 9.09357 23.9024 9.84378 23.8999H14.1929C14.9413 23.8992 15.6624 23.6188 16.2147 23.1137C16.7669 22.6087 17.1104 21.9151 17.1779 21.1699L17.4915 17.7381C16.8645 18.3257 16.1428 18.8136 15.3518 19.1761L15.186 20.9899C15.1636 21.2384 15.049 21.4695 14.865 21.6378C14.6809 21.8061 14.4407 21.8996 14.1913 21.8999L9.84195 21.8999L9.83789 21.8999Z"
      fill="#F7C59F"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconWatch',
}
</script>
