<template>
  <section class="cta-start-now">
    <div class="container">
      <h3 class="cta-start-now__title">Begin nu!</h3>
      <p class="cta-start-now__text">
        Jouw rijbewijs is slechts een paar stappen verwijderd.
      </p>
      <div class="cta-start-now__postal-form-step-container">
        <PostalFormStep :progress-value="0" />
      </div>
    </div>
  </section>
</template>

<script>
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  name: 'CTAStartNow',

  components: {
    PostalFormStep,
  },
}
</script>
