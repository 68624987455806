<template>
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" fill="#004E89" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.5 13.5V17.5H17.5V13.5H13.5ZM13.5 22.5V26.5H17.5V22.5H13.5ZM13.5 31.5V35.5H17.5V31.5H13.5ZM13 12C12.4477 12 12 12.4477 12 13V18C12 18.5523 12.4477 19 13 19H18C18.5523 19 19 18.5523 19 18V13C19 12.4477 18.5523 12 18 12H13ZM22.25 14C21.5596 14 21 14.5596 21 15.25C21 15.9404 21.5596 16.5 22.25 16.5H34.75C35.4404 16.5 36 15.9404 36 15.25C36 14.5596 35.4404 14 34.75 14H22.25ZM22.25 23C21.5596 23 21 23.5596 21 24.25C21 24.9404 21.5596 25.5 22.25 25.5H34.75C35.4404 25.5 36 24.9404 36 24.25C36 23.5596 35.4404 23 34.75 23H22.25ZM21 33.25C21 32.5596 21.5596 32 22.25 32H34.75C35.4404 32 36 32.5596 36 33.25C36 33.9404 35.4404 34.5 34.75 34.5H22.25C21.5596 34.5 21 33.9404 21 33.25ZM12 22C12 21.4477 12.4477 21 13 21H18C18.5523 21 19 21.4477 19 22V27C19 27.5523 18.5523 28 18 28H13C12.4477 28 12 27.5523 12 27V22ZM13 30C12.4477 30 12 30.4477 12 31V36C12 36.5523 12.4477 37 13 37H18C18.5523 37 19 36.5523 19 36V31C19 30.4477 18.5523 30 18 30H13Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCheckList',
}
</script>
