<template>
  <div class="service-form-part">
    <PreferencesPartnerField :choices="choices" v-on="$listeners" />
  </div>
</template>

<script>
import PreferencesPartnerField from 'chimera/all/themes/mars/components/form/fields/preferencePartner/PreferencePartnerField'
import { Selectable } from 'chimera/all/components/models/Selectable'
import TSelectableButton from 'chimera/all/components/elements/TSelectableButton'

export default {
  name: 'PreferencePartnerFormPart',

  components: {
    PreferencesPartnerField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          'Laagste prijzen',
          'Laagste prijzen',
          'Laagste prijzen',
          { component: TSelectableButton },
        ),
        new Selectable(
          'Direct beginnen',
          'Direct beginnen',
          'Direct beginnen',
          { component: TSelectableButton },
        ),
        new Selectable('Snelle cursus', 'Snelle cursus', 'Snelle cursus', {
          component: TSelectableButton,
        }),
        new Selectable(
          'Slagingspercentage',
          'Slagingspercentage',
          'Slagingspercentage',
          { component: TSelectableButton },
        ),
      ],
    }
  },
}
</script>
