<template>
  <t-form ref="form" @submit.prevent>
    <ServiceTypeFormPart @result="onResult" @change="onChange" />
  </t-form>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import ServiceTypeFormPart from '~/components/form/part/serviceType/ServiceTypeFormPart'
import NextStep from '~/pages/offertes-aanvragen/wat-is-belangrijk'

export default {
  name: 'SelectTypeFormStep',

  components: {
    ServiceTypeFormPart,
  },

  extends: AbstractFormStep,

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
