<template>
  <div
    :id="id"
    class="t-selectable t-selectable-button"
    :class="[
      {
        't-selectable--selected': isSelected,
      },
      colorVariant,
    ]"
    @click="onClick()"
  >
    <div class="t-selectable__wrapper">
      <input
        :id="id + '-selectable'"
        :role="role"
        :type="type"
        class="t-selectable__input"
        :value="value"
      />

      <label
        :for="id + '-selectable'"
        class="t-selectable__label"
        @click.prevent
      >
        <div v-if="label" class="t-selectable__text-container">
          <span class="t-selectable__text">{{ label }}</span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
import AbstractTSelectable from 'chimera/all/components/elements/AbstractTSelectable'

export default {
  name: 'TSelectableButton',

  extends: AbstractTSelectable,
}
</script>
