<template>
  <FormModal show-back-btn next-btn-text="Volgende">
    <template #body>
      <h1 class="form-modal__title">
        {{ $t('title') }}
      </h1>
      <p class="form-modal__description">
        {{ $t('description') }}
      </p>
      <SelectPreferencePartnerFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/mars/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import SelectPreferencePartnerFormStep from '~/components/form/steps/selectPreferencePartner/selectPreferencePartnerFormStep'

export default {
  name: 'TypeAutorijles',

  components: {
    SelectPreferencePartnerFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat is belangrijk',
      path: '/offertes-aanvragen/wat-is-belangrijk',
      progressValue: 25,
      checkoutStep: 2,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat is belangrijker voor je?",
    "description": "Selecteer de opties die het beste overeenkomen met wat je zoekt."
  }
}
</i18n>
