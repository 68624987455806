<template>
  <section class="why-us">
    <div class="container">
      <h3 class="why-us__title">Waarom wij?</h3>

      <div class="why-us__content">
        <ul class="why-us__reasons">
          <li class="reason">
            <div class="reason__icon-container">
              <icon-thumbs-up class="reason__icon" />
            </div>
            <div class="reason__text-container">
              <h6 class="reason__title">
                Wij hebben de beste partners voor jou
              </h6>
              <p class="reason__text">
                Wij hebben alvast
                <em class="reason__text--emphasis"
                  >de beste rijscholen van Nederland geselecteerd.</em
                >
                Het enige wat jij hoeft te doen is het formulier in te vullen,
                zodat we je kunnen matchen met de ideale partners in jouw regio.
              </p>
            </div>
          </li>

          <li class="reason">
            <div class="reason__icon-container">
              <icon-watch class="reason__icon" />
            </div>
            <div class="reason__text-container">
              <h6 class="reason__title">
                Wij helpen je tijd en geld te besparen
              </h6>
              <p class="reason__text">
                Bespaar tijd door de partners contact met jou op te laten nemen
                zodat jij op maat gemaakte offertes kunt ontvangen. Bespaar geld
                door de offertes te vergelijken en de rijschool te kiezen die
                het beste bij je past.
              </p>
            </div>
          </li>

          <li class="reason">
            <div class="reason__icon-container">
              <icon-award class="reason__icon" />
            </div>
            <div class="reason__text-container">
              <h6 class="reason__title">Wij werken met vertrouwde partners</h6>
              <p class="reason__text">
                Wij hebben in heel Nederland meer dan
                <em class="reason__text--emphasis"
                  >700 vertrouwde en hoog gewaardeerde rijscholen</em
                >
                geselecteerd.
              </p>
            </div>
          </li>

          <li class="reason">
            <div class="reason__icon-container">
              <icon-smile class="reason__icon" />
            </div>
            <div class="reason__text-container">
              <h6 class="reason__title">Wij willen blije klanten</h6>
              <p class="reason__text">
                Meer dan
                <em class="reason__text--emphasis">214.735 klanten</em> hebben
                al gebruik gemaakt van onze service en hebben een rijschool
                gevonden via een van onze partners.
              </p>
            </div>
          </li>
        </ul>
        <div class="why-us__partners">
          <h6 class="why-us__partners__title">
            <em class="title--emphasis">Geverifieerde partners</em> door heel
            Nederland
          </h6>
          <MapNetherlands class="why-us__partners__image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IconThumbsUp from 'chimera/all/themes/mars/images/icons/why-us/IconThumbsUp'
import IconWatch from 'chimera/all/themes/mars/images/icons/why-us/IconWatch'
import IconAward from 'chimera/all/themes/mars/images/icons/why-us/IconAward'
import IconSmile from 'chimera/all/themes/mars/images/icons/why-us/IconSmile'
import MapNetherlands from 'chimera/all/themes/mars/components/lottie/MapNetherlands'

export default {
  name: 'WhyUs',

  components: {
    IconThumbsUp,
    IconWatch,
    IconAward,
    IconSmile,
    MapNetherlands,
  },
}
</script>
