<template>
  <section class="how-it-works">
    <div class="container">
      <div class="how-it-works__title-container">
        <h6 class="how-it-works__sub-title">Hoe werkt het?</h6>
        <h2 class="how-it-works__title">
          Het kost je minder dan
          <em class="how-it-works__title--emphasis">1 minuut</em> om <br /><em
            class="how-it-works__title--emphasis"
            >tot 40% te besparen!</em
          >
        </h2>
      </div>
      <div class="how-it-works__content">
        <ul class="how-it-works__steps">
          <li class="step">
            <div class="step__icon-container">
              <icon-pen class="step__icon" />
            </div>
            <div class="step__text-container">
              <h6 class="step__title">Beantwoord een paar vragen</h6>
              <p class="step__text">
                We vragen wat informatie over wat je zoekt, waar je zoekt en hoe
                de partners contact met je kunnen opnemen.
                <em class="step__text--emphasis"
                  >Dan matchen we je met de juiste rijscholen in jouw regio.</em
                >
              </p>
            </div>
          </li>

          <li class="step">
            <div class="step__icon-container">
              <icon-check-list class="step__icon" />
            </div>
            <div class="step__text-container">
              <h6 class="step__title">Ontvang tot 6 gratis offertes</h6>
              <p class="step__text">
                In plaats van meerdere bedrijven te zoeken en te proberen te
                bereiken, sturen de bedrijven jou hun offertes,
                <em class="step__text--emphasis"
                  >waardoor je tijd en moeite bespaart.</em
                >
              </p>
            </div>
          </li>

          <li class="step">
            <div class="step__icon-container">
              <icon-wheel-with-star class="step__icon" />
            </div>
            <div class="step__text-container">
              <h6 class="step__title">Kies de beste rijschool</h6>
              <p class="step__text">
                Dan hoef je alleen nog maar
                <em class="step__text--emphasis"
                  >offertes en diensten te vergelijken en de rijschool te kiezen
                  die bij je past!</em
                >
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import IconPen from 'chimera/all/themes/mars/images/icons/how-it-works/IconPen'
import IconCheckList from 'chimera/all/themes/mars/images/icons/how-it-works/IconCheckList'
import IconWheelWithStar from 'chimera/all/themes/mars/images/icons/how-it-works/IconWheelWithStar'

export default {
  name: 'HowItWorks',

  components: {
    IconPen,
    IconCheckList,
    IconWheelWithStar,
  },
}
</script>
