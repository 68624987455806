<template>
  <div class="page-index">
    <Header class="header--center">
      <div class="flex flex-col justify-center">
        <PostalFormStep :progress-value="0" />
      </div>
    </Header>

    <HowItWorks />

    <WhyUs />

    <CTAStartNow />
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HowItWorks from 'chimera/all/themes/mars/components/HowItWorks'
import WhyUs from 'chimera/all/themes/mars/components/WhyUs'
import CTAStartNow from 'chimera/all/themes/mars/components/CTAStartNow'
import Header from 'chimera/all/themes/mars/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  components: {
    Header,
    HowItWorks,
    WhyUs,
    CTAStartNow,
    PostalFormStep,
  },

  extends: AbstractHomePage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Autorijscholenvergelijker.nl',
      headTitleTemplate: '%s',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top Autorijscholen uit jouw regio. ✓Dé beste prijs voor jouw rijschool ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/',
    }
  },
}
</script>
