<template>
  <div class="relative flex flex-col items-center w-full md:w-80">
    <t-form
      ref="form"
      class="postal-form-step"
      @submit="onSubmit"
      @submit.prevent
    >
      <PostalField
        class="postal-form-step__input"
        autocomplete="postal-code"
        :placeholder="$t('postalField.placeholder')"
        @result="onResult"
        @change="onChange"
      />

      <FormStepSubmitButton
        id="submitPostal"
        class="postal-form-step__btn t-btn--primary"
        :text="$t('postalField.buttonText')"
      />

      <ServiceField
        ref="service"
        hidden
        :init-with-value="serviceId"
        @result="onResult"
        @change="onChange"
      />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import PostalField from 'chimera/all/components/form/fields/postal/PostalField'
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import ServiceField from 'chimera/all/components/form/fields/service/ServiceField'
import { drivingSchoolLicenseBNL } from 'chimera/drivingSchool/service'
import NextStep from '~/pages/offertes-aanvragen/type-autorijles'

export default {
  name: 'PostalFormStep',

  components: {
    FormErrorMessages,
    PostalField,
    ServiceField,
  },

  extends: AbstractFormStep,

  /**
   * @returns {{serviceId: string}}
   */
  data() {
    return {
      serviceId: drivingSchoolLicenseBNL.id,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "postalField": {
      "placeholder": "Vul jouw postcode in",
      "buttonText": "Begin"
    }
  }
}
</i18n>
