<template>
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill="#004E89"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23 32.5C23 31.9477 23.4477 31.5 24 31.5H33C33.5523 31.5 34 31.9477 34 32.5C34 33.0523 33.5523 33.5 33 33.5H24C23.4477 33.5 23 33.0523 23 32.5Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 16.3787C29.7026 16.3787 29.4174 16.4968 29.2071 16.7071L16.903 29.0112L16.3744 31.1256L18.4888 30.597L30.7929 18.2929C30.897 18.1888 30.9796 18.0651 31.036 17.9291C31.0923 17.793 31.1213 17.6472 31.1213 17.5C31.1213 17.3527 31.0923 17.2069 31.036 17.0709C30.9796 16.9348 30.897 16.8112 30.7929 16.7071C30.6888 16.603 30.5652 16.5204 30.4291 16.464C30.2931 16.4077 30.1473 16.3787 30 16.3787ZM27.7929 15.2929C28.3783 14.7075 29.1722 14.3787 30 14.3787C30.4099 14.3787 30.8158 14.4594 31.1945 14.6163C31.5732 14.7731 31.9173 15.003 32.2071 15.2929C32.497 15.5827 32.7269 15.9268 32.8837 16.3055C33.0406 16.6842 33.1213 17.0901 33.1213 17.5C33.1213 17.9099 33.0406 18.3158 32.8837 18.6945C32.7269 19.0732 32.497 19.4172 32.2071 19.7071L19.7071 32.2071C19.579 32.3352 19.4184 32.4262 19.2426 32.4701L15.2426 33.4701C14.9018 33.5553 14.5413 33.4555 14.2929 33.2071C14.0445 32.9587 13.9447 32.5982 14.0299 32.2574L15.0299 28.2574C15.0738 28.0816 15.1648 27.921 15.2929 27.7929L27.7929 15.2929Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPen',
}
</script>
